<template>
  <aside id="cta-ribbon" class="section">
    <div class="container">
      <span class="cta-ebook-cover"></span>
      <p>Mainframe Modernization <strong>Playbook</strong></p>
      <router-link to="/mainframe-modernization-playbook" class="button"
        ><span>Download The Playbook</span
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.969"
          height="11.663"
          viewBox="0 0 12.969 11.663"
        >
          <g>
            <g
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="2"
            >
              <path
                stroke-linejoin="round"
                d="M7.489 1.414l4.418 4.418-4.418 4.417"
              />
              <path d="M1 5.892h10.969" />
            </g>
          </g>
        </svg>
      </router-link>
    </div>
  </aside>
</template>

<script>
export default {
  name: "TheCTARibbon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#cta-ribbon {
  padding-top: 34px;
  padding-bottom: 41px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1440' height='188' viewBox='0 0 1440 188'><defs/><defs><linearGradient id='a' x1='1' x2='.284' y1='1' y2='1' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='%2301506c'/><stop offset='1' stop-color='%230f171c'/></linearGradient></defs><path fill='url(%23a)' d='M0 0h1440v188H0z' data-name='Rectangle 193'/></svg>");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  .container {
    position: relative;
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
    }
    .cta-ebook-cover {
      z-index: 0;
      display: block;
      width: 100%;
      max-width: 638px;
      margin: -22% auto 24px;
      @media screen and (min-width: 768px) {
        margin: 0;
        position: absolute;
        left: ((645/1156) * 100%);
        top: 42%;
        transform: translateY(-50%);
      }
      &:after {
        content: "";
        background-image: url("/assets/images/cta-ebook.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: block;
        width: 100%;
        padding-top: (208 / 319) * 100%;
      }
    }
  }
  p {
    max-width: 642px;
    position: relative;
    z-index: 1;
    font-weight: normal;
    font-style: italic;
    font-size: ((34/18) * 1rem);
    line-height: (46/34);
    text-align: center;
    color: #fff;
    margin: 0 auto 36px;
    @media screen and (min-width: 768px) {
      width: ((642/1156) * 100%);
      text-align: left;
      margin: 0 0 16px;
    }
  }
  .button {
    position: relative;
    z-index: 1;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    height: 51px;
    padding: 14px ((24/1156) * 100%) 13px ((27/1156) * 100%);
    background: linear-gradient(#85bc22 0%, #01aeeb 100%);
    margin: 0 auto;
    transition: 300ms ease;
    &:hover {
      background-image: linear-gradient(to bottom, #85bc22 -93%, #01aeeb);
    }
    @media screen and (min-width: 768px) {
      margin: 0;
    }
    span {
      display: block;
      font-weight: bold;
      font-size: 1rem;
      line-height: (38/18);
      text-align: left;
      color: #fff;

      text-transform: uppercase;
      padding-right: 16px;
    }
    svg {
      display: block;
      flex-shrink: 0;
      flex-grow: 0;
      background: transparent;
    }
  }
}
</style>
