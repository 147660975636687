<template>
  <section class="section" id="contact-us">
    <div class="container">
      <h2 class="title">Connect with Deloitte</h2>
      <p class="subtitle">
        Looking to learn more about transforming your enterprise workloads with
        Deloitte and AWS? Fill out the form below and a member of our team will
        be in touch.
      </p>
      <div class="form-wrapper">
        <div class="form-success" v-if="showContact">
          <p>Thank you for contacting us.</p>
        </div>
        <form
          name="mainframe-modernization-playbook-form"
          id="mainframe-modernization-playbook-form"
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
          v-else
        >
          <input type="hidden" name="oid" value="00D15000000EfGF" />
          <input
            type="hidden"
            name="retURL"
            :value="`${url}/?contact=true#contact-us`"
          />

          <input type="hidden" name="Campaign_ID" value="7016R000000wVRgQAM" />

          <!-- UTM_Source -->
          <input
            type="hidden"
            name="00N1C00000IoupT"
            id="00N1C00000IoupT"
            :value="utmParams.source"
          />
          <!-- UTM Medium -->
          <input
            type="hidden"
            name="00N1C00000IoupY"
            id="00N1C00000IoupY"
            :value="utmParams.medium"
          />
          <!-- UTM Campaign -->
          <input
            type="hidden"
            name="00N1C00000Ioupi"
            id="00N1C00000Ioupi"
            :value="utmParams.campaign"
          />

          <div class="form-column-wrap">
            <div class="form-column-one">
              <div class="form-group">
                <label for="first_name">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="first_name"
                  name="first_name"
                  required
                  maxlength="40"
                />
              </div>
              <div class="form-group">
                <label for="last_name">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="last_name"
                  name="last_name"
                  required
                  maxlength="80"
                />
              </div>
              <div class="form-group">
                <label for="email">Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                  required
                  maxlength="80"
                />
              </div>
              <div class="form-group">
                <label for="phone">Phone Number</label>
                <input
                  type="tel"
                  pattern="^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$"
                  class="form-control"
                  id="phone"
                  name="phone"
                  required
                  maxlength="40"
                />
              </div>
            </div>
            <div class="form-column-two">
              <div class="form-group">
                <label for="company">Company</label>
                <input
                  type="text"
                  class="form-control"
                  id="company"
                  name="company"
                  required
                  maxlength="40"
                />
              </div>

              <div class="form-group">
                <label for="title">Job Title</label>
                <input
                  type="text"
                  class="form-control"
                  id="title"
                  name="title"
                  required
                  maxlength="40"
                />
              </div>
              <div class="form-group">
                <label for="country">Country</label>
                <div class="select">
                  <select
                    name="country"
                    id="country"
                    class="form-control"
                    required
                    :class="{ 'country-selected': country }"
                    v-model="country"
                  >
                    <option value="" selected="" disabled="" hidden="">
                      Select Your Country
                    </option>
                    <option
                      v-for="country in countriesList"
                      :key="`${country.name} option`"
                      :value="country.name"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                  <span class="focus"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group checkbox-group">
            <input
              type="checkbox"
              required
              id="00N1C00000IkcVh"
              name="00N1C00000IkcVh"
              class=""
            />
            <div>
              <label for="00N1C00000IkcVh">
                <div class="small">
                  I agree to receive emailed reports, articles, and event
                  invitations from Deloitte. I understand I may unsubscribe at
                  any time by selecting the link included in e-mails. See
                  Deloitte
                  <a
                    href="https://www2.deloitte.com/us/en/footerlinks1/privacy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Privacy Terms</a
                  >
                </div>
              </label>
            </div>
          </div>
          <div class="form-group checkbox-group">
            <input
              type="checkbox"
              required
              id="00N1C00000IkhX0"
              name="00N1C00000IkhX0"
              class=""
            />
            <div>
              <label for="00N1C00000IkhX0">
                <div class="small">
                  The submission of personal information through this page is
                  subject to Deloitte's
                  <a
                    href="https://www2.deloitte.com/us/en/footerlinks1/privacy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Privacy Statement</a
                  >
                  and
                  <a
                    href="https://www2.deloitte.com/us/en/footerlinks1/terms-of-use.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Legal Terms</a
                  >. I have read and accept the terms of use.
                </div>
              </label>
            </div>
          </div>
          <div class="form-group">
            <button
              type="submit"
              name="submit"
              id="submit"
              class="btn btn-lg download green-btn mt-5"
            >
              Contact Us
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import countriesData from "../data/countries.json";

export default {
  name: "ContactUs",
  props: {
    utmParams: {
      type: Object,
      required: false,
      default: function () {
        return {
          source: "",
          medium: "",
          campaign: "",
        };
      },
    },
    showContact: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      url: process.env.VUE_APP_URL,
      showModal: false,
      country: "",
      countriesList: countriesData,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#contact-us {
  background-image: url("/assets/images/backgrounds/contact-us.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 69px;
  padding-bottom: 109px;
  .container {
    max-width: 823px;
    .title {
      color: #fff;
    }
    .subtitle {
      font-weight: 600;
      font-size: ((20/18) * 1rem);
      line-height: (26/20);
      text-align: left;
      color: #fff;
      margin-top: 0;
      margin-bottom: 31px;
    }
  }
  .form-wrapper {
    background: #f8f8f8;
    padding: 56.3px ((32.435/823) * 100%) 40px;
    .form-group {
      margin-bottom: 19.7px;
      .small {
        font-weight: normal;
        font-size: ((13/18) * 1rem);
        line-height: (16/13);
        text-align: left;
        color: #696969;
        a {
          color: #01aeeb;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.checkbox-group {
        margin-top: 14.3px;
        display: flex;
        flex-direction: row;
      }
      label {
        display: block;
        font-weight: 300;
        font-size: ((19/18) * 1rem);
        line-height: (26/19);
        text-align: left;
        color: #000;
        margin-bottom: 2px;
      }
      input {
        font-weight: 300;
        font-size: ((19/18) * 1rem);
        line-height: (24/19);
        text-align: left;
        color: #000;
        display: block;
        width: 100%;
        height: 47.53px;
        line-height: 47.53px;
        border-radius: 3px;
        background: #fff;
        border: 1px solid #4e4e4e;
        padding-left: ((17.1/322.31) * 100%);
        padding-right: ((17.1/322.31) * 100%);
        outline: none;
        &[type="checkbox"] {
          margin-top: 5px;
          margin-right: 15px;
          flex-shrink: 0;
          flex-grow: 0;
          width: 17px;
          height: 17px;
          background: #fff;
          border: 1px solid #707070;
          border-radius: 0;
        }
        &:focus {
          border: 2px solid #000;
        }
      }
      .select {
        border-radius: 3px;
        background: #fff;
        border: 1px solid #4e4e4e;
        position: relative;
        display: grid;
        grid-template-areas: "select";
        align-items: center;
        width: 100%;
        select,
        &:after {
          grid-area: select;
        }
        select {
          border-radius: 3px;
          // A reset of styles, including removing the default dropdown arrow
          appearance: none;
          // Additional resets for further consistency
          border: none;
          height: 47.53px;
          width: 100%;
          font-weight: 300;
          font-size: ((19/18) * 1rem);
          line-height: (24/19);
          text-align: left;
          color: #969696;
          display: block;
          padding-left: ((17.1/322.31) * 100%);
          padding-right: 35.61px;
          outline: none;
          &.country-selected,
          option {
            color: #000;
          }

          &:focus + .focus {
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border: 2px solid #000;
            border-radius: inherit;
          }
        }
        &:after {
          content: "";
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='11.509' height='6.461' viewBox='0 0 11.509 6.461'><path id='Path_764' data-name='Path 764' d='M1146.871,4279.978l5.4,5.4,5.4-5.4' transform='translate(-1146.517 -4279.625)' fill='none' stroke='%23707070' stroke-width='1'/></svg>");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: left center;
          width: 35.61px;
          height: 6.461px;
          justify-self: end;
        }
      }

      #submit {
        height: auto;
        display: block;
        border: none;
        width: 100%;
        max-width: 362px;
        padding: 18px ((49.7/758.2) * 100%) 18px;
        margin: 44.3px auto 0;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='362' height='60' viewBox='0 0 362 60'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' y1='-0.426' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%2385bc22'/%3E%3Cstop offset='1' stop-color='%2301aeeb'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_27' data-name='Rectangle 27' width='362' height='60' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
        background-repeat: repeat-x;
        background-size: 100% auto;
        background-position: bottom;
        font-weight: bold;
        font-size: 1rem;
        line-height: (24/18);
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        transition: 300ms ease-in-out;
        &:hover {
          background-size: 300% auto;
        }
      }
    }
  }
  .form-column-wrap {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .form-column-one,
      .form-column-two {
        width: 48%;
        max-width: 362.13px;
      }
    }
  }
  .form-success {
    margin: 26px auto 42.3px;
    p {
      font-weight: 600;
      font-size: 1.222rem;
      color: #000;
      text-align: center;
    }
  }
}
</style>
