<template>
  <article class="steps-to-transform-slide">
    <div class="content" :class="slide.layout">
      <template v-if="slide.layout === 'title-slide'">
        <header>
          <h2>{{ slide.title }}</h2>
          <p class="subtitle">{{ slide.subtitle }}</p>
        </header>
        <p class="details">{{ slide.details }}</p>
      </template>
      <template v-else>
        <p class="step-title">{{ slide.title }}</p>
      </template>
      <button
        class="button-step-next"
        v-on:click="$emit('next-step')"
        v-if="slide.next"
      >
        <span v-html="slide.next" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.818"
          height="14.764"
          viewBox="0 0 16.818 14.764"
        >
          <g>
            <path
              stroke-linejoin="round"
              d="M9.766 1.413l5.968 5.968-5.968 5.968"
            />
            <path d="M1 7.463h14.818" />
          </g>
        </svg>
      </button>
    </div>
    <span
      class="slide-background-image"
      aria-hidden="true"
      :style="{ backgroundImage: `url(${slide.background})` }"
    />
  </article>
</template>

<script>
export default {
  name: "SuccessStoriesItem",
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.steps-to-transform-slide {
  .content {
    position: relative;
    z-index: 99;
  }
  .slide-background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    z-index: 0;
  }
  .title-slide {
    background-color: rgba(#fff, 0.83);
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 4%;
    padding-right: 4%;
    width: 100%;
    max-width: 562px;
    @media screen and (min-width: 768px) {
      padding-top: 59px;
      padding-bottom: 47px;
      padding-left: ((57/1156) * 100%);
      padding-right: ((42/1156) * 100%);
    }
    h2 {
      font-size: ((32/18) * 1rem);
      line-height: (50/40);
      color: #fff;
      text-align: left;
      color: #000;

      @media screen and (min-width: 768px) {
        font-size: ((40/18) * 1rem);
      }
    }
    .subtitle {
      max-width: 400px;
      font-weight: 600;
      font-size: ((24/18) * 1rem);
      line-height: (26/24);
      text-align: left;
      color: #000;
      margin-top: 18px;
      margin-bottom: 0;
    }
    .details {
      max-width: 400px;
      font-weight: normal;
      font-size: ((20/18) * 1rem);
      line-height: (26/20);
      text-align: left;
      color: #000;
      margin-top: 12px;
      margin-bottom: 0;
    }
    .button-step-next {
      margin-top: 40px;
      width: 100%;
      max-width: 360px;
      height: 69px;
      padding-left: ((24/463) * 100%);
      padding-right: ((24/463) * 100%);
      background: linear-gradient(#85bc22 0%, #01aeeb 100%);
      color: #fff;
      font-weight: bold;
      font-size: 1rem;
      line-height: (38/18);
      text-align: left;
      color: #fff;
      svg {
        margin-left: 0;
      }
    }
  }
  .step-slide {
    padding-top: 93px;
    max-width: 562px;
    .step-title {
      font-weight: normal;
      font-size: ((32/18) * 1rem);
      line-height: (48/40);
      text-align: left;
      color: #fff;
      margin-top: 0;
      margin-bottom: 3px;
      @media screen and (min-width: 768px) {
        font-size: ((40/18) * 1rem);
      }
    }
    .button-step-next {
      color: #85bc22;
      svg {
        margin-left: 12px;
        margin-bottom: 2px;
        g {
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-width: 2px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: currentColor;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        &:before {
          visibility: visible;
          width: 100%;
        }
      }
    }
  }
  .button-step-next {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-top: 33px;
    font-weight: bold;
    font-size: 1rem;
    line-height: (38/18);
    text-align: left;
    text-transform: uppercase;
    position: relative;
    svg {
      margin-left: 12px;
      margin-bottom: 2px;
      g {
        fill: none;
        stroke: currentColor;
        stroke-linecap: round;
        stroke-width: 2px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
  }
  &.current-slide {
    .slide-background-image {
      z-index: 1;
    }
  }
}
</style>
