<template>
  <section class="section" id="success-stories">
    <div class="container">
      <h2 class="title">Migration Success Stories</h2>
      <div class="success-stories-nav">
        <template v-for="navItem in stories">
          <button
            class="success-stories-nav-item"
            :class="{ 'current-story': currentStory === navItem.id }"
            v-on:click="currentStory = navItem.id"
            v-html="navItem.category"
            :key="`nav-item-${navItem.id}`"
          />
        </template>
      </div>
    </div>
    <div class="stories-list">
      <success-stories-item
        v-for="(story, index) in stories"
        :key="story.id"
        :class="{ 'current-story': story.id === currentStory }"
        :story="story"
        :index="index"
      />
    </div>
  </section>
</template>

<script>
import SuccessStoriesItem from "./SuccessStoriesItem.vue";
export default {
  components: { SuccessStoriesItem },
  name: "TheSuccessStories",
  props: {
    stories: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      currentStory: null,
      storyCategories: null,
    };
  },
  mounted: function () {
    this.setInitialStory();
  },
  methods: {
    setInitialStory: function () {
      this.currentStory = this.stories[0].id;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#success-stories {
  position: relative;
  padding-top: 53px;
  padding-bottom: 53px;
  background-color: rgb(0, 0, 0);
  @media screen and (min-width: 1024px) {
    padding-bottom: 80px;
  }
  h2 {
    color: rgb(255, 255, 255);
  }
  .success-stories-nav {
    margin-top: 32px;
    position: relative;
    z-index: 1;
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .success-stories-nav-item {
      cursor: pointer;
      display: block;
      flex-grow: 0;
      background: none;
      border: none;
      font-weight: bold;
      font-size: 1rem;
      line-height: (26/20);
      text-align: left;
      text-transform: uppercase;
      color: rgb(255, 255, 255);
      padding: 0 0 2px;
      margin-bottom: 26px;
      position: relative;
      transition: color 300ms ease;
      @media screen and (min-width: 768px) {
        display: block;
        margin-bottom: 0;
        max-width: 196px;
      }
      @media screen and (min-width: 1024px) {
        max-width: 242px;
      }
      @media screen and (min-width: 1280px) {
        font-size: ((20/18) * 1rem);
        max-width: none;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #01aeeb;
        transition: all 0.3s ease-in-out;
      }
      &:hover,
      &:active,
      &:focus,
      &.current-story {
        color: #01aeeb;
        &:after {
          width: 100%;
        }
      }
    }
  }
  .container {
    position: relative;
    z-index: 999;
  }
  .stories-list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    @media screen and (min-width: 768px) {
      padding-top: 72px;
    }
    .success-story {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      grid-area: 1 / 1 / 2 / 2;
      opacity: 0;
      z-index: 0;
      transition: 300ms ease 100ms;
      &.current-story {
        transition: 400ms ease-out;
        opacity: 1;
        z-index: 1;
      }
    }
  }
}
</style>
