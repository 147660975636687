import { render, staticRenderFns } from "./TheSuccessStories.vue?vue&type=template&id=023ca162&scoped=true&"
import script from "./TheSuccessStories.vue?vue&type=script&lang=js&"
export * from "./TheSuccessStories.vue?vue&type=script&lang=js&"
import style0 from "./TheSuccessStories.vue?vue&type=style&index=0&id=023ca162&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023ca162",
  null
  
)

export default component.exports