import App from "./App.vue";
import Vue from "vue";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(VueMeta);

if (process.env.NODE_ENV !== "development") {
  Vue.use(
    VueGtag,
    {
      config: { id: "G-K4M2MBHNW2" },
    },
    router
  );
}

if (process.env.NODE_ENV === "development") {
  const VueAxe = require("vue-axe").default;
  Vue.use(VueAxe);
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
