<template>
  <article class="reason">
    <figure class="reason-icon">
      <img :src="reason.icon" alt="" />
    </figure>
    <div class="reason-content">
      <h3>{{ reason.name }}</h3>
      <p>{{ reason.details }}</p>
    </div>
  </article>
</template>

<script>
export default {
  name: "CardReason",
  props: {
    reason: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.reason {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  .reason-icon {
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0 auto 18px;
    text-align: center;
    max-width: 108px;
    width: ((139/530) * 100%);
    @media screen and (min-width: 768px) {
      max-width: 120px;
      text-align: left;
      margin: 0 calc((28 / 530) * 100%) 0 0;
    }
    @media screen and (min-width: 1024px) {
      max-width: 139px;
    }
    img {
      width: 100%;
    }
  }
  .reason-content {
    h3 {
      font-weight: bold;
      font-size: ((26/18) * 1rem);
      line-height: 1;
      text-align: center;
      color: #01aeeb;
      @media screen and (min-width: 768px) {
        text-align: left;
      }
    }
    p {
      text-align: center;
      color: #585858;
      margin: 13px 0 0;
      @media screen and (min-width: 768px) {
        text-align: left;
      }
    }
  }
}
</style>
