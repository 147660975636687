<template>
  <article class="success-story" :id="`${story.slug}`">
    <div class="container">
      <div class="story-header">
        <div class="content">
          <h3 v-html="story.title" />
          <p v-html="story.subtitle" />
          <div class="story-cta">
            <a :href="story.cta.link" :class="`${story.cta.type}-cta`"
              >{{ story.cta.label }}
              <span class="link-icon" v-if="story.cta.type" aria-hidden="true">
                <svg
                  v-if="story.cta.type === 'link'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.818"
                  height="14.764"
                  viewBox="0 0 16.818 14.764"
                >
                  <g>
                    <path
                      stroke-linejoin="round"
                      d="M9.766 1.413l5.968 5.968-5.968 5.968"
                    />
                    <path d="M1 7.463h14.818" />
                  </g>
                </svg>
                <svg
                  v-if="story.cta.type === 'download'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.503"
                  height="19"
                  viewBox="0 0 21.503 19"
                >
                  <g>
                    <path
                      stroke-linejoin="round"
                      d="M16.436 9.766l-5.968 5.968L4.5 9.766"
                    />
                    <path d="M10.386 1v14.818" />
                    <path d="M1 18h19.503" />
                  </g>
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="story-details">
        <div class="story-services">
          <h4>Services</h4>
          <ul>
            <li v-for="service in story.services" :key="service">
              {{ service }}
            </li>
          </ul>
        </div>
        <div class="story-results">
          <h4>Results</h4>
          <ul>
            <li v-for="result in story.results" :key="result">{{ result }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="story-background"
      :style="{ backgroundImage: `url(${story.background})` }"
      aria-hidden="true"
    ></div>
  </article>
</template>

<script>
export default {
  name: "SuccessStoriesItem",
  props: {
    story: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.success-story {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  .container {
    z-index: 1;
    position: relative;
    z-index: 4;
    @media screen and (min-width: 768px) {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 32px 1fr 32px 1fr 32px 1fr 32px 1fr 32px 1fr 32px
        1fr 32px 1fr 32px 1fr 32px 1fr 32px 1fr 32px 1fr;
      grid-template-columns: repeat(12, 1fr);
      -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 32px;
      grid-row-gap: 0px;
    }
  }
  .story-header {
    flex-shrink: 0;
    @media screen and (min-width: 768px) {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 11;
      grid-area: 1 / 1 / 2 / 7;
    }
    z-index: 333;
    .content {
      background: linear-gradient(90deg, #01aeeb 0%, #85bc22 100%);
      margin-top: 24px;
      padding: 33px ((25/562) * 100%) 42px ((23/562) * 100%);
      @media screen and (min-width: 768px) {
        margin-top: 45px;
        padding: 63px ((55/562) * 100%) 72px ((53/562) * 100%);
      }
    }
    h3 {
      font-weight: normal;
      font-style: italic;
      font-size: ((28/18) * 1rem);
      line-height: (48/40);
      text-align: left;
      color: #fff;
      @media screen and (min-width: 1024px) {
        font-size: ((40/18) * 1rem);
      }
    }
    p {
      font-weight: normal;
      font-size: ((20/18) * 1rem);
      line-height: (26/20);
      text-align: left;
      color: #fff;
    }
    .story-cta {
      font-weight: bold;
      font-size: 1rem;
      line-height: (38/18);
      text-align: left;
      color: #fff;
      a {
        font-weight: bold;
        font-size: 1rem;
        line-height: (38/18);
        text-align: left;
        color: #fff;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        .link-icon {
          padding-left: 12px;
          svg {
            margin-bottom: 2px;
            g {
              fill: none;
              stroke: currentColor;
              stroke-linecap: round;
              stroke-width: 2px;
            }
          }
        }
        &.download-cta {
          .link-icon {
            svg {
              width: 19.5px;
              height: 17px;
            }
          }
        }
        &.link-cta {
          .link-icon {
            svg {
            }
          }
        }
        &:before {
          content: "";
          position: absolute;
          width: 0;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: currentColor;
          visibility: hidden;
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          &:before {
            visibility: visible;
            width: 100%;
          }
        }
      }
    }
  }
  .story-details {
    background-color: #efefef;
    padding: 25px ((25/562) * 100%) 35px ((23/562) * 100%);
    @media screen and (min-width: 768px) {
      padding: 45px ((67/661) * 100%) 55px ((99 / 661) * 100%);
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 11;
      -ms-grid-column-span: 23;
      grid-area: 1 / 6 / 2 / 13;
    }
    h4 {
      font-weight: bold;
      font-size: ((16/18) * 1rem);
      line-height: (26/16);
      text-align: left;
      text-transform: uppercase;
      color: #01aeeb;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        display: flex;
        flex-direction: row;
        list-style: none;
        &:before {
          content: "•";
          width: 13px;
          margin-right: 6px;
          flex-shrink: 0;
          flex-grow: 0;
          font-weight: normal;
          font-size: 1rem;
          line-height: (32/18);
          text-align: left;
          color: #01aeeb;
        }
      }
    }
    .story-services {
      font-weight: normal;
      font-size: 1rem;
      line-height: (32/18);
      text-align: left;
      color: #585858;
      margin-bottom: 32px;
    }
    .story-results {
      font-weight: normal;
      font-size: 1rem;
      line-height: (24/18);
      text-align: left;
      color: #585858;
      ul {
        li {
          &:not(:last-child) {
            margin-bottom: 24px;
          }
          &:before {
            line-height: (24/18);
          }
        }
      }
    }
  }
  .story-background {
    background-color: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
  }
}
.current-story {
  transition: 400ms ease-out;
  opacity: 1;
  z-index: 4;
  .story-background {
    transition: 300ms ease-out 100;
    opacity: 1;
    z-index: 3;
  }
}
</style>
