<template>
  <header class="section has-video-bg" id="hero">
    <div class="container">
      <div class="branding">
        <img src="/assets/images/logo.svg" alt="Logos for AWS and Deloitte" />
      </div>
      <div class="content">
        <h1>Migrate. Modernize. <strong>Transform.</strong></h1>
        <p>
          Build the foundation for innovation by transforming your enterprise
          workloads today.
        </p>
      </div>
    </div>
    <video
      class="video-bg"
      aria-hidden="true"
      autoplay
      muted
      loop
      playsinline
      poster="/assets/images/backgrounds/hero.jpg"
    >
      <source src="/assets/hero.mp4" type="video/mp4" />
    </video>
  </header>
</template>

<script>
export default {
  name: "TheHero",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#hero {
  background-image: url(/assets/images/backgrounds/hero.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 45px;
  padding-bottom: 45px;
  min-height: 610px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .container {
    position: relative;
    z-index: 1;
    flex-grow: 1;
  }
  .content {
    max-width: 661px;
    position: absolute;
    top: 56%;
    transform: translateY(-50%);
    margin: 0;
  }
  .branding {
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
    }
    img {
      max-width: 92%;
    }
  }
  h1 {
    font-size: ((48/18) * 1rem);
    font-weight: normal;
    font-style: italic;
    line-height: (78/68);
    text-align: left;
    color: #fff;
    @media screen and (min-width: 425px) {
      font-size: ((58/18) * 1rem);
    }
    @media screen and (min-width: 768px) {
      font-size: ((68/18) * 1rem);
    }
  }

  p {
    font-weight: normal;
    font-size: 20px;
    line-height: (38/28);
    text-align: left;
    color: #fff;
    @media screen and (min-width: 425px) {
      font-size: ((28/18) * 1rem);
    }
  }
}
</style>
