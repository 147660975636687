<template>
  <div class="home">
    <TheHeader />
    <TheCTARibbon />
    <main>
      <section class="section has-video-bg" id="migration-made-possible">
        <div class="container">
          <h2 class="title">Mainframe Migration Made Possible</h2>
          <p>
            Accelerate your digital transformation by renovating the legacy
            enterprise applications and data that form the foundation of your
            business. Deloitte and AWS can help you break free from the upgrade
            and refresh cycle and transform IT from a cost center to an
            innovation engine. Our fully automated modernization solution
            combines decades of industry experience with the most extensive
            global cloud infrastructure on the market to deliver
            cost-optimization, scale, performance, and resiliency. That’s the
            joint power of Deloitte and AWS.
          </p>
        </div>
        <video
          class="video-bg"
          aria-hidden="true"
          autoplay
          muted
          loop
          playsinline
          poster="/assets/images/backgrounds/made-possible-bg.jpg"
        >
          <source
            src="/assets/migration-made-possible-bg.mp4"
            type="video/mp4"
          />
        </video>
      </section>
      <section class="section" id="why-deloitte-and-aws">
        <div class="container">
          <h2 class="title">Why Deloitte and AWS</h2>
          <p class="subtitle">Powerful modernization experience and scale</p>
          <div class="reasons">
            <card-reason
              :reason="reason"
              v-for="reason in reasons"
              :key="reason.id"
            />
          </div>
        </div>
      </section>
      <the-success-stories :stories="successStories" />
      <section class="section" id="industry-solutions">
        <div class="container">
          <h2 class="title">Industry Solutions</h2>
          <div class="solutions">
            <card-solution
              :solution="solution"
              v-for="solution in industrySolutions"
              :key="solution.id"
            />
          </div>
        </div>
      </section>
      <the-steps-to-transform :stepsToTransform="stepsToTransform" />
      <section class="section" id="resources">
        <div class="container">
          <h2 class="title">Resources</h2>
          <div class="resources-list">
            <card-resource
              v-for="resource in resources"
              :key="resource.id"
              :resource="resource"
            />
          </div>
        </div>
      </section>
      <contact-us :showContact="showContact" :utmParams="utmParams" />
    </main>
    <the-footer />
  </div>
</template>

<script>
// @ is an alias to /src
import TheHeader from "@/components/TheHeader.vue";
import CardReason from "../components/CardReason.vue";
import CardSolution from "../components/CardSolution.vue";
import TheSuccessStories from "../components/TheSuccessStories.vue";
import TheStepsToTransform from "../components/TheStepsToTransform.vue";
import CardResource from "../components/CardResource.vue";
import TheFooter from "../components/TheFooter.vue";
import TheCTARibbon from "../components/TheCTARibbon.vue";
import { mapState } from "vuex";
import ContactUs from "../components/ContactUs.vue";
export default {
  name: "Home",
  metaInfo: {
    title: "AWS | Deloitte: Migrate. Modernize. Transform.",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      { "http-equiv": "Content-Type", content: "text/html; charset=UTF-8" },
    ],
  },
  components: {
    TheHeader,
    CardReason,
    CardSolution,
    TheSuccessStories,
    TheStepsToTransform,
    CardResource,
    TheFooter,
    TheCTARibbon,
    ContactUs,
  },
  data: function () {
    return {
      showContact: false,
      reasons: [
        {
          id: "f6154490-92d4-4c76-a98c-a9113ab829a2",
          name: "Unmatched Experience",
          details:
            "With over ten years of experience migrating workloads for thousands of the world’s largest enterprises, Deloitte and AWS have designed and honed tools to reduce risk and simplify the modernization process.",
          icon: "/assets/images/icons/unmatched-experience.svg",
        },
        {
          id: "2fffc941-7d70-451d-9385-aa136850dbbf",
          name: "Agility",
          details:
            "Deloitte and AWS are innovating faster than any other provider. Our efficient, optimized processes mean that you can focus on developing new products and services faster to quickly respond to market and regulatory demands and achieve a better overall market position.",
          icon: "/assets/images/icons/agility.svg",
        },
        {
          id: "8b3129dc-640a-4b9c-b1af-85d9fc66a7ef",
          name: "Lower Costs",
          details:
            "Now more than ever, enterprises are under pressure to cut costs, and IT infrastructure is often at the center of this discussion. Deloitte and AWS can help you break the upgrade and refresh cycle for your enterprise workloads while optimizing software licensing to drive costs down.",
          icon: "/assets/images/icons/lower-costs.svg",
        },
        {
          id: "956eda88-d86b-403f-b10f-b52c13380e1d",
          name: "Scale",
          details:
            "Deloitte and AWS bring a broad set of analytics, artificial intelligence, machine learning, and scalable database options to leverage your valuable business data and drive true change.",
          icon: "/assets/images/icons/scale.svg",
        },
      ],
      successStories: [
        {
          id: "a95159dc-baf8-414c-a9c7-ea2fe9a6e30b",
          slug: "government-public-services-story",
          background: "/assets/images/success-stories/gov-public-services.jpg",
          title:
            "Deloitte transforms a <nobr>25-year-old</nobr> mainframe system for the Utah Department of Human Services with AWS",
          subtitle:
            "Cloud migration increases efficiency and lowers cost for Utah public agency",
          category: "Government &amp; Public Services",
          cta: {},
          services: [
            "AWS GovCloud",
            "Amazon Virtual Private Cloud",
            "AWS Direct Connect",
            "Amazon EC2",
          ],
          results: [
            "With AWS, the DHS Office of Recovery Services can now process 600K transactions per day for the thousands of families who depend on child support in Utah.",
            "Infrastructure procurement and setup time was reduced from weeks to a matter of hours through the AWS and SaltStack solution.",
            "Transforming the existing COBOL. programming language into modern language Java allowed easier augmentation of new developers.",
            "Horizontal scaling improved batch performance while Amazon EC2 allowed for rapid adjustments to underlying infrastructure based on load (e.g., transaction volume).",
          ],
        },
        {
          id: "101d67e4-22d0-4fdb-ab4c-e0156d586d2c",
          slug: "tech-media-telecom-story",
          background: "/assets/images/success-stories/tech-media-telecom.jpg",
          title:
            "Thomson Reuters completes large-scale cloud migration with Deloitte and AWS",
          subtitle:
            "News conglomerate leverages Deloitte insights for a smooth cloud transition",
          category: "Technology, Media, &amp; Telecommunications",
          cta: {},
          services: ["Amazon SageMaker"],
          results: [
            "Thousands of servers and hundreds of revenue-generating apps were migrated to AWS five months ahead of schedule.",
            "Deloitte helped Thomson Reuters create a roadmap for future innovations using their extensive cloud knowledge.",
            "Developers and data scientists gained insights from real-time and historical data in the cloud.",
            "Machine learning model-building tool Amazon SageMaker saved product developers countless hours of coding, shortening time-to-market.",
          ],
        },
        {
          id: "6025845b-a0ab-4942-9f44-efbb6b443fc4",
          slug: "financial-services-story",
          background: "/assets/images/success-stories/financial-services.jpg",
          title:
            "Pekin Insurance unlocks legacy data to drive growth and profitability",
          subtitle:
            "Insurance company shrinks development time by months through cloud transformation",
          category: "Financial Services",
          cta: {},
          services: [
            "AWS CloudFormation",
            "AWS CodeDeploy",
            "Amazon WorkSpaces",
          ],
          results: [
            "Pekin’s enterprise transformation program increased profitability by 10% and earned an Impact Award from the Novarica Insurance Technology Research Council.",
            "Moving to AWS reduced Pekin’s time-to-market from 8-12 weeks to 2-3 weeks, increasing the number of yearly deployments from six to 28.",
            "Pekin expanded from six environments running in its data center to 36 environments in AWS.",
            "Cloud elasticity now saves the company $30,000 a year in operational costs, per environment.",
          ],
        },
      ],
      industrySolutions: [
        {
          id: "a1874fd0-d00a-496f-bd30-f6f892c35cfb",
          name: "Consumer",
          titleRows: "one",
          details:
            "Get closer to your customers in the automotive; consumer products; retail; and transportation, hospitality & services sectors by turning legacy data into business insights.",
          background: "/assets/images/industry-solutions/consumer.jpg",
        },
        {
          id: "c57ae95a-438f-4cbe-898b-b2ae38f8ccd0",
          name: "Energy, Resources, &amp; Industrials",
          titleRows: "two",
          details:
            "Power innovation with cloud migration in the oil, gas & chemicals; power, utilities &amp; renewables; and industrial products &amp; construction sectors.",
          background:
            "/assets/images/industry-solutions/energy-resources-industrials.jpg",
        },
        {
          id: "475444da-634c-48ee-be41-95017031b6aa",
          name: "Financial Services",
          titleRows: "one",
          details:
            "Invest in your business’s future by embracing mainframe migration across banking &amp; capital markets, insurance, investment management, and real estate.",
          background:
            "/assets/images/industry-solutions/financial-services.jpg",
        },
        {
          id: "3074ca3a-8729-4c3d-8f56-0d11dbe6de5b",
          name: "Government &amp; Public Services",
          titleRows: "two",
          details:
            "Boost sustainability and cost savings for public agencies by replacing physical data centers with AWS cloud.",
          background:
            "/assets/images/industry-solutions/gov-public-services.jpg",
        },
        {
          id: "ab0fd553-1cbe-462f-8cf0-0caa8a02994c",
          name: "Life Sciences and Health Care",
          titleRows: "two",
          details:
            "Modernize enterprise workloads to transform patient experiences, improve care delivery, and quickly respond to emerging needs in life sciences &amp; health care.",
          background:
            "/assets/images/industry-solutions/life-sciences-health-care.jpg",
        },
        {
          id: "1e01d51c-d0ac-4936-a6f4-2f5cd2fed6a2",
          name: "Technology, Media, &amp; Telecommunications",
          titleRows: "two",
          details:
            "Leverage cloud solutions that boost agility across TMT workloads to compete in an ever-changing market.",
          background:
            "/assets/images/industry-solutions/tech-media-telecoms.jpg",
        },
      ],
      stepsToTransform: [
        {
          id: "1e6de7b9-d87c-427d-ac9d-a002433dd75b",
          layout: "title-slide",
          background:
            "/assets/images/steps-to-transform/steps-to-transform-intro.jpg",
          title: "5 Steps to Transform Your Enterprise Workloads",
          subtitle: "It’s time to migrate, modernize and transform.",
          details:
            "Accelerate modernization while leveraging current knowledge and creating time to upskill through the Migrate to Modernize cloud approach from Deloitte and AWS.",
          next: "Step one: The simplest path",
        },
        {
          id: "e4c4070f-4e20-414d-9d5b-4cd3203ee727",
          layout: "step-slide",
          background:
            "/assets/images/steps-to-transform/steps-to-transform-one.jpg",
          title:
            "Start with the simplest path to cloud for most applications: migrate first, modernize second.",
          next: "Step Two: Break down complex symptoms",
        },
        {
          id: "06f75de6-fe2c-4fa1-be65-8b36e4a696f8",
          layout: "step-slide",
          background:
            "/assets/images/steps-to-transform/steps-to-transform-two.jpg",
          title:
            "Break down complex systems into thin slices that can be rapidly iterated for continuous progress.",
          next: "Step Three: Identify the &ldquo;low-hanging fruit&rdquo;",
        },
        {
          id: "afe76b67-4ea3-4bbf-a4ec-13f09fe725e7",
          layout: "step-slide",
          background:
            "/assets/images/steps-to-transform/steps-to-transform-three.jpg",
          title:
            "Identify the “low-hanging fruit” for early cloud successes that can get buy-in from stakeholders.",
          next: "Step Four: Build workflows, toolsets, and skills",
        },
        {
          id: "6fe82889-02cd-49c7-a987-5ac70cc5ef62",
          layout: "step-slide",
          background:
            "/assets/images/steps-to-transform/steps-to-transform-four.jpg",
          title:
            "Build workflows, toolsets, and skills needed for cloud strategies through methodical investments.",
          next: "Step Five: Create a safe &ldquo;landing zone&rdquo;",
        },
        {
          id: "20e8de80-2ce5-435c-aa51-35eee7da0fba",
          layout: "step-slide",
          background:
            "/assets/images/steps-to-transform/steps-to-transform-five.jpg",
          title:
            "Create a safe “landing zone” for cloud-hosted apps that get modernized.",
          next: "",
        },
      ],
      resources: [
        {
          id: "be37875a-a637-4e84-9f02-875654f0189d",
          image: {
            src: "/assets/images/mainframe-ebook-ribbon.png",
            alt: "Cover image for the 'Your Mainframe Modernization Playbook'",
          },
          name: "Your Mainframe Modernization Playbook",
          tagline: "How to tell your mainframe &ldquo;It’s Over&rdquo;",
          details:
            "Made for business decision-makers, jump-start your digital transformation with these tips for a streamlined cloud migration.",
          cta: {
            label: "Download the Playbook",
            link: "/mainframe-modernization-playbook",
          },
        },
        {
          id: "042f25be-a4fa-443d-a2f8-db60317a8f02",
          image: {
            src: "/assets/images/best-practices-ebook-ribbon.png",
            alt: "Cover image for the '5 Best Practices in Mainframe Modernization' guide",
          },
          name: "5 Best Practices in Mainframe Modernization",
          tagline: "The quick-start guide to mainframe modernization",
          details:
            "Created for a technical audience, discover how to remove barriers to the cloud when migrating and modernizing your enterprise workloads.",
          cta: {
            label: "Download Now",
            link: "/mainframe-modernization-best-practices",
          },
        },
      ],
    };
  },
  computed: mapState({
    utmParams: (state) => state.sessionData.utmParams,
  }),
  mounted() {
    this.$store.dispatch("sessionData/getUtmParams", this.$router.currentRoute);
    if (
      this.$router.currentRoute.query["contact"] &&
      this.$router.currentRoute.query["contact"] !== "false"
    ) {
      this.showContact = true;
    } else {
      this.showContact = false;
    }
  },
};
</script>

<style lang="scss" scoped>
#migration-made-possible {
  background-image: url(/assets/images/backgrounds/made-possible-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 74px 0;
  @media screen and (min-width: 768px) {
    padding: 132px 0;
  }
  .container {
    max-width: 958px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  h2 {
    margin: 0;
  }
  p {
    margin-top: 30px;
    margin-bottom: 0;
  }
}

#why-deloitte-and-aws {
  padding-top: 72px;
  padding-bottom: 48px;
  .container {
  }
  h2 {
    text-align: center;
    color: #01aeeb;
  }
  .subtitle {
    font-weight: 600;
    font-size: ((20/18) * 1rem);
    line-height: (26/20);
    text-align: center;
    color: #585858;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .reasons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 74px;

    @media screen and (min-width: 1024px) {
      justify-content: space-between;
    }
    .reason {
      margin: 0 0 52px;
      width: 100%;
      max-width: 680px;
      @media screen and (min-width: 768px) {
        margin: 52px 0;
      }
      @media screen and (min-width: 1024px) {
        width: 46%;
        max-width: 530px;
      }
    }
  }
}

#industry-solutions {
  padding-top: 91px;
  padding-bottom: 188px;
  h2 {
    color: #01aeeb;
  }
  .solutions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 57px;
    .solution {
      width: 100%;
      margin: 16px 0;
      @media screen and (min-width: 768px) {
        width: ((562/1156) * 100%);
        max-width: 562px;
      }
    }
  }
}

#resources {
  padding-top: 51px;
  padding-bottom: 66px;
  h2 {
    margin-bottom: 42px;
    color: #01aeeb;
  }
  .resources-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
