<template>
  <article class="resource">
    <div class="resource-image">
      <figure>
        <router-link :to="resource.cta.link">
          <img
            :src="resource.image.src"
            v-if="resource.image"
            :alt="resource.image.alt"
          />
        </router-link>
      </figure>
    </div>
    <div class="resource-content">
      <header>
        <h3>
          <router-link :to="resource.cta.link">
            {{ resource.name }}
          </router-link>
        </h3>
        <p class="subtitle" v-html="resource.tagline" />
      </header>
      <p v-html="resource.details" />
      <footer>
        <router-link :to="resource.cta.link" class="resource-cta">
          {{ resource.cta.label }}
          <span class="link-icon" aria-hidden="true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.503"
              height="19"
              viewBox="0 0 21.503 19"
            >
              <g>
                <path
                  stroke-linejoin="round"
                  d="M16.436 9.766l-5.968 5.968L4.5 9.766"
                />
                <path d="M10.386 1v14.818" />
                <path d="M1 18h19.503" />
              </g></svg></span
        ></router-link>
      </footer>
    </div>
  </article>
</template>

<script>
export default {
  name: "CardResource",
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.resource {
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
  width: 100%;
  max-width: 463px;
  margin: 0 auto 42px;
  @media screen and (min-width: 768px) {
    width: 48%;
    margin: 0;
  }
  .resource-image {
    display: block;
    width: 100%;
    figure {
      width: 100%;

      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='463' height='208' viewBox='0 0 463 208'><defs><linearGradient id='a' x1='.966' x2='.048' y1='.012' y2='.981' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='%23ededed'/><stop offset='1' stop-color='%23a9b4b7'/></linearGradient></defs><path fill='url(%23a)' d='M0 0h208v463H0z' data-name='Rectangle 39' transform='rotate(-90 104 104)'/></svg>");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      margin: 0 0 29px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .resource-content {
    h3,
    h3 a {
      font-weight: bold;
      font-size: ((22/18) * 1rem);
      line-height: (26/22);
      text-align: left;
      color: #01aeeb;
      margin-top: 0;
    }
    h3 {
      a {
        text-decoration: none;
        position: relative;
        background-image: linear-gradient(currentColor, currentColor);
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 0% 2px;
        transition: background-size ease-in-out 0.3s;
        &:hover {
          background-size: 100% 2px;
        }
      }
    }
    .subtitle {
      font-weight: 600;
      margin-bottom: 0;
    }
    p {
      margin: 12px 0 31px;
      text-align: left;
      color: #585858;
    }
  }
  .resource-cta {
    font-weight: 600;
    font-size: 1rem;
    line-height: (24/18);
    text-align: left;
    color: #01aeeb;
    position: relative;
    text-decoration: none;
    display: inline-flex;
    align-items: center;

    justify-content: space-between;
    .link-icon {
      padding-left: 12px;
      svg {
        width: 13.37px;
        height: 11.66px;
        margin-bottom: 2px;
        g {
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-width: 2px;
        }
      }
    }
    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &:before {
        visibility: visible;
        width: 100%;
      }
    }
  }
}
</style>
