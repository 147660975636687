const state = {
  utmParams: {
    source: null,
    medium: null,
    campaign: null,
  },
};

const getters = {};

const actions = {
  getUtmParams({ commit }, route) {
    const source = route.query["utm_source"] ? route.query["utm_source"] : "";
    const medium = route.query["utm_medium"] ? route.query["utm_medium"] : "";
    const campaign = route.query["utm_campaign"]
      ? route.query["utm_campaign"]
      : "";
    commit("setUtmParams", {
      source: source,
      medium: medium,
      campaign: campaign,
    });
  },
};

const mutations = {
  setUtmParams(state, params) {
    state.utmParams = params;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
