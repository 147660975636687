<template>
  <article
    class="solution"
    :style="{ backgroundImage: `url(${solution.background})` }"
    tabindex="0"
  >
    <div class="solution-content" :class="`title-rows-${solution.titleRows}`">
      <header>
        <h3 v-html="solution.name"></h3>
      </header>
      <p v-html="solution.details" />
    </div>
  </article>
</template>

<script>
export default {
  name: "CardSolution",
  props: {
    solution: {
      type: Object,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.solution {
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  @media screen and (min-width: 1024px) {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (191 / 281) * 100%;
    @media screen and (min-width: 1024px) {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='562' height='191' viewBox='0 0 562 191'><defs/><defs><linearGradient id='a' x1='.5' x2='.5' y2='1' gradientUnits='objectBoundingBox'><stop offset='0' stop-opacity='0'/><stop offset='1' stop-color='%23121212'/></linearGradient></defs><path fill='url(%23a)' d='M0 0h562v191H0z' data-name='Rectangle 147'/></svg>");
      background-repeat: repeat-x;
      background-position: bottom center;
    }
  }
  &:after {
    @media screen and (min-width: 1024px) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background: linear-gradient(#01aeeb 0%, #85bc22 100%);
      opacity: 0;
      z-index: 1;
      transition: 300ms ease;
    }
  }
  .solution-content {
    @media screen and (min-width: 1024px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      transition: 400ms ease-in;
      z-index: 99;
      padding-left: ((50/562) * 100%);
      padding-right: ((30.3/562) * 100%);
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateY(calc(100% - 88px));
      &.title-rows-one {
        transform: translateY(calc(100% - 72px));
        h3 {
          height: 42px;
        }
      }
    }
  }

  header {
    @media screen and (min-width: 1024px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      transition: 300ms ease;
      &:after {
        content: "";
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='27.234' height='27.234' viewBox='0 0 27.234 27.234' class='solution-expand-collapse-icon'><g fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2'><path d='M13.617 1v25.234' /><path d='M1 13.617h25.234' /></g></svg>");
        background-repeat: no-repeat;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 25.23px;
        width: 25.23px;
        flex-shrink: 0;
        flex-grow: 0;
        transition: 300ms ease;
      }
    }
  }
  h3 {
    font-weight: normal;
    font-size: ((32/18) * 1rem);
    line-height: (36/32);
    text-align: left;
    color: #01aeeb;
    margin: 22px 0 12px;
    @media screen and (min-width: 1024px) {
      margin: 0;
      display: flex;
      height: 72px;
      max-width: 422px;
      align-items: center;
      transition: 300ms ease-in;
      transition-delay: 200ms;
      flex-shrink: 1;
      flex-grow: 0;
      color: #fff;
    }
  }
  p {
    font-weight: normal;
    text-align: left;
    margin: 0;
    padding-bottom: 38px;
    transition: 300ms ease;
    @media screen and (min-width: 1024px) {
      color: #fff;
      opacity: 0;
      max-height: 0%;
    }
  }
  @media screen and (min-width: 1024px) {
    &:hover,
    &:focus,
    &:active {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 0.93;
      }
      .solution-content {
        transform: translateY(0);
        header {
          padding-bottom: 29px;
          &:after {
            transform: rotate(135deg);
          }
        }
        p {
          max-height: 100%;
          opacity: 1;
          transform: translateY(-10px);
        }
      }
    }
  }
}
</style>
