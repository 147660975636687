<template>
  <section class="section" id="steps-to-transform">
    <transition name="fade">
      <div
        class="container"
        id="section-title-container"
        v-if="currentSlideType !== 'title-slide'"
      >
        <h2 class="section-title">
          5 Steps to Transform Your Enterprise Workloads
        </h2>
      </div>
    </transition>
    <div class="container" id="slides-container">
      <steps-to-transform-step
        :slide="slide"
        v-for="(slide, index) in stepsToTransform"
        :key="slide.id"
        v-on:next-step="nextStep()"
        :class="{ 'current-slide': currentSlide === index }"
      />
    </div>
    <transition name="fade">
      <div
        class="container"
        id="slides-nav-container"
        v-if="currentSlideType !== 'title-slide'"
      >
        <nav>
          <button
            class="slide-nav-item nav-arrow arrow-prev"
            v-on:click="prevStep()"
            title="Previous Step"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.44"
              height="28.879"
              viewBox="0 0 15.44 28.879"
            >
              <defs />
              <path
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14.026 27.465L1 14.439 14.026 1.414"
                data-name="Path 728"
              />
            </svg>
          </button>
          <button
            class="slide-nav-item specific-slide"
            :class="{ 'current-nav-item': currentSlide === index }"
            v-for="index in totalSlides"
            :key="`slide-nav-number-${index}`"
            v-on:click="goToStep(index)"
            :title="`Go To Step ${index}`"
          >
            {{ index }}
          </button>
          <button
            class="slide-nav-item nav-arrow arrow-next"
            v-on:click="nextStep()"
            title="Next Step"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.44"
              height="28.879"
              viewBox="0 0 15.44 28.879"
            >
              <defs />
              <path
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1.414 1.414L14.44 14.44 1.414 27.465"
                data-name="Path 729"
              />
            </svg>
          </button>
        </nav>
      </div>
    </transition>
    <div
      class="container"
      id="slides-nav-arrow-container"
      :class="{ 'title-slide': currentSlideType === 'title-slide' }"
    >
      <button
        class="nav-arrow arrow-prev"
        v-on:click="prevStep()"
        title="Previous Step"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15.44"
          height="28.879"
          viewBox="0 0 15.44 28.879"
        >
          <defs />
          <path
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14.026 27.465L1 14.439 14.026 1.414"
            data-name="Path 728"
          />
        </svg>
      </button>
      <button
        class="nav-arrow arrow-next"
        v-on:click="nextStep()"
        title="Next Step"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15.44"
          height="28.879"
          viewBox="0 0 15.44 28.879"
        >
          <defs />
          <path
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1.414 1.414L14.44 14.44 1.414 27.465"
            data-name="Path 729"
          />
        </svg>
      </button>
    </div>
  </section>
</template>

<script>
import StepsToTransformStep from "./StepsToTransformStep.vue";
export default {
  components: { StepsToTransformStep },
  name: "TheStepsToTransform",
  props: {
    stepsToTransform: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      currentSlide: 0,
      storyCategories: null,
      totalSlides: 0,
      currentSlideType: null,
    };
  },
  mounted: function () {
    this.currentSlideType = this.stepsToTransform[0].layout;
    this.totalSlides = this.stepsToTransform.length - 1;
    //  this.setInitialStory();
  },
  methods: {
    setCurrentSlide: function () {
      this.currentStory = this.stories[0].id;
    },
    goToStep: function (step) {
      this.currentSlide = step;
      this.currentSlideType = this.stepsToTransform[step].layout;
    },
    nextStep: function () {
      if (this.currentSlide === this.totalSlides) {
        this.currentSlide = 0;
        this.currentSlideType = this.stepsToTransform[0].layout;
      } else {
        this.currentSlide++;
        this.currentSlideType = this.stepsToTransform[this.currentSlide].layout;
      }
    },
    prevStep: function () {
      if (this.currentSlide === 0) {
        this.currentSlide = this.totalSlides;
        this.currentSlideType = this.stepsToTransform[this.totalSlides].layout;
      } else {
        this.currentSlide--;
        this.currentSlideType = this.stepsToTransform[this.currentSlide].layout;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#steps-to-transform {
  position: relative;
  padding-top: 66px;
  padding-bottom: 90px;
  background: #000;
  @media screen and (min-width: 768px) {
    padding-top: 66px;
    padding-bottom: 90px;
  }
  #section-title-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 91px;
    margin: auto;
    z-index: 999;
    h2 {
      font-weight: normal;
      font-style: italic;
      font-size: ((24/18) * 1rem);
      line-height: (28/24);
      text-align: left;
      color: #85bc22;
    }
  }
  #slides-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .steps-to-transform-slide {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 1 / 1 / 2 / 2;
    opacity: 0;
    z-index: 1;
    transition: 400ms linear;
    &.current-slide {
      transition: 300ms ease-out;
      opacity: 1;
      z-index: 2;
    }
  }
  #slides-nav-container {
    position: absolute;
    bottom: 35px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    @media screen and (min-width: 768px) {
      bottom: 65px;
    }
    nav {
      display: flex;
      flex-direction: row;
      width: 110px;
      justify-content: space-between;
      @media screen and (min-width: 768px) {
        width: 450px;
      }
      @media screen and (min-width: 1280px) {
        width: 314px;
      }
      .slide-nav-item {
        cursor: pointer;
        padding: 0;
        width: 42px;
        height: 42px;
        background: #585858;
        border: none;
        font-weight: normal;
        font-size: 1rem;
        text-align: center;
        line-height: (28/18);
        color: #fff;
        border-radius: 0;
        transition: 300ms ease;
        &.specific-slide {
          display: none;
          @media screen and (min-width: 768px) {
            display: block;
          }
        }
        &.nav-arrow {
          display: block;
          @media screen and (min-width: 1280px) {
            display: none;
          }
        }
        &:hover,
        &.current-nav-item {
          background: #85bc22;
        }
      }
    }
  }
  #slides-nav-arrow-container {
    display: none;
    position: absolute;
    z-index: 22;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1327.03px;
    @media screen and (min-width: 1280px) {
      display: block;
    }
    .nav-arrow {
      position: absolute;
      background: none;
      border: none;
      cursor: pointer;
      &.arrow-prev {
        left: 0;
      }
      &.arrow-next {
        right: 0;
      }
    }
    &.title-slide {
      .nav-arrow {
        svg {
          path {
            stroke: #000;
          }
        }
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
