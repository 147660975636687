<template>
  <footer class="section" id="footer">
    <div id="aws-deloitte-footer">
      <div class="container" id="branding-row">
        <div class="branding">
          <img
            src="/assets/images/logo-footer.svg"
            alt="Logos for AWS and Deloitte"
          />
        </div>
        <div class="social-media">
          <ul>
            <li>
              <a
                href="https://www.instagram.com/deloitte/?hl=en"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Connect with Deloitte on Instagram"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34.618"
                  height="33.707"
                  data-name="Group 293"
                  viewBox="0 0 34.618 33.707"
                >
                  <defs />
                  <g data-name="Group 292">
                    <g data-name="Group 291">
                      <g data-name="Group 290">
                        <path
                          fill="#fff"
                          d="M28.339 0H6.279A6.216 6.216 0 000 6.114v21.479a6.216 6.216 0 006.279 6.113h22.06a6.215 6.215 0 006.278-6.113V6.114A6.215 6.215 0 0028.339 0zm-4.076 4.739a1.354 1.354 0 011.418-1.34h4.127a1.363 1.363 0 011.4 1.115 1.125 1.125 0 01.016.213v3.946a1.331 1.331 0 01-1.161 1.309 1.5 1.5 0 01-.267.016h-2.063c-.688 0-1.374-.006-2.063 0a1.387 1.387 0 01-1.4-1.15 1.37 1.37 0 01-.011-.186q.004-1.96.004-3.923zm-6.957 5.266a6.614 6.614 0 11-6.956 6.607 6.789 6.789 0 016.959-6.61zm13.917 4.146v14.343a1.322 1.322 0 01-1.108 1.3 1.988 1.988 0 01-.392.03q-5.994.005-11.984 0H4.908a1.889 1.889 0 01-.544-.066 1.327 1.327 0 01-.961-1.146 2.178 2.178 0 01-.008-.213V13.973h3.139a9.964 9.964 0 001.077 7.823 10.636 10.636 0 004.342 4.081 11.554 11.554 0 0014.316-2.99 10.1 10.1 0 001.823-8.91h3.13z"
                          data-name="Path 712"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/deloitteus?lang=en"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Connect with Deloitte on Twitter"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.042"
                  height="30.916"
                  data-name="Group 299"
                  viewBox="0 0 38.042 30.916"
                >
                  <defs />
                  <path
                    fill="#fff"
                    d="M38.042 3.658a15.476 15.476 0 01-4.481 1.23A7.819 7.819 0 0036.99.571a15.623 15.623 0 01-4.954 1.895 7.81 7.81 0 00-13.3 7.119A22.159 22.159 0 012.65 1.429 7.81 7.81 0 005.066 11.85a7.768 7.768 0 01-3.537-.977v.1a7.814 7.814 0 006.261 7.654 7.851 7.851 0 01-2.056.274 7.67 7.67 0 01-1.469-.143 7.815 7.815 0 007.291 5.422 15.671 15.671 0 01-9.693 3.34A16.464 16.464 0 010 27.413a22.108 22.108 0 0011.964 3.5c14.356 0 22.207-11.891 22.207-22.2 0-.338-.007-.676-.022-1.009a15.843 15.843 0 003.893-4.046z"
                    data-name="Path 714"
                  /></svg
              ></a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/deloitte/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Connect with Deloitte on Facebook"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.854"
                  height="35.264"
                  viewBox="0 0 16.854 35.264"
                >
                  <defs />
                  <path
                    fill="#fff"
                    d="M3.642 7.102v5.048H0v6.178h3.642v16.94h7.483v-16.94h5.022s.47-2.96.7-6.2h-5.691v-4.22a1.778 1.778 0 011.623-1.479h4.078V.001h-5.546C3.457 0 3.642 6.18 3.642 7.102z"
                  /></svg
              ></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/deloitte/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Connect with Deloitte on Linkedin"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.746"
                  height="29.154"
                  viewBox="0 0 31.746 29.154"
                >
                  <defs />
                  <path
                    fill="#fff"
                    d="M6.988 29.154H.798V9.343h6.19zM3.735 6.872h-.044a3.817 3.817 0 11.044 0zm28.012 22.282h-7.016V18.907c0-2.685-1.1-4.513-3.513-4.513a3.547 3.547 0 00-3.351 2.428 4.527 4.527 0 00-.15 1.613v10.719h-6.95s.087-18.155 0-19.808h6.95v3.112c.412-1.362 2.631-3.3 6.178-3.3 4.394 0 7.852 2.85 7.852 8.981z"
                    data-name="Path 710"
                  /></svg
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="viewstream-footer">
      <div class="container">
        <div class="created-by">
          <span>Site created by</span>
          <a
            href="https://viewstream.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/viewstream-logo.svg" alt="Viewstream"
          /></a>
        </div>
        <div class="legal-copy">
          <ul>
            <li>© 2021 Viewstream, Inc. All rights reserved.</li>
            <li>
              <a
                href="https://viewstream.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a
                href="https://viewstream.com/terms"
                target="_blank"
                rel="noopener noreferrer"
                >Terms of Service &amp; Cookie Policy</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#footer {
  #aws-deloitte-footer {
    background: #f5f5f5;
    padding-top: 65px;
    padding-bottom: 87px;
    #branding-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
    }
    .branding {
      margin-top: 28px;
      margin-bottom: 28px;
      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
    }
    .social-media {
      width: 100%;
      max-width: 364px;
      margin-top: 28px;
      margin-bottom: 28px;
      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        list-style: none;
        li {
          a {
            display: flex;
            width: 68px;
            height: 68px;
            border-radius: 34px;
            background-color: #85bc22;
            align-items: center;
            justify-content: center;
            transition: 300ms ease;
            &:hover,
            &:active,
            &:focus {
              background-color: #01aeeb;
            }
          }
        }
      }
    }
  }
  #viewstream-footer {
    padding-top: 28px;
    padding-bottom: 38px;
    .created-by {
      margin-bottom: 14px;
      span {
        display: block;
        font-family: "Source Sans Pro";
        font-weight: normal;
        font-size: 11px;
        line-height: (15/11);
        text-align: left;
        color: #7c7c7c;
        margin-bottom: 3px;
      }
    }
    .legal-copy {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        @media screen and (min-width: 768px) {
          display: flex;
          flex-direction: row;
        }
        li {
          color: #494949;
          display: block;
          font-family: "Source Sans Pro";
          font-weight: normal;
          font-size: 11px;
          line-height: (15/11);
          text-align: left;
          color: #7c7c7c;
          a {
            color: #7c7c7c;
            display: inline;
          }
          @media screen and (min-width: 768px) {
            &:after {
              content: "|";
              display: inline-block;
              padding: 0 4px 0 2px;
            }
            &:last-of-type,
            &:first-of-type {
              &:after {
                content: " ";
                display: inline-block;
                padding: 0 4px 0 2px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
